import React from "react";
import ButtonGroup from "../../components/basics/button/button-group/ButtonGroup";
import Button from "../../components/basics/button/Button";
import {Field, initialize, reduxForm} from "redux-form";
import _ from "lodash";
import {connect} from "react-redux";
import ReduxLabeledInput from "../../components/basics/input/labeled-input/ReduxLabeledInput";
import OperatorChooser from "../../components/basics/operator-chooser/OperatorChooser";
import ReduxLabeledSelect from "../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {submit, validate} from "./EditSaleSubmit";
import {UnitTypes} from "../../constans/unitTypes";
import {convertWeightUnitTo, getUnit} from "../../utils/UnitUtils";
import {withTranslation} from "react-i18next";
import * as UserTypes from "validators-schema/Api/constants/userTypes";

const FormName = "edit-sale";

export class EditSale extends React.Component {

    constructor(props) {
        super(props);
        let sale = _.cloneDeep(this.props.sale);
        this.props.dispatch(initialize(FormName, {
            ...sale,
            Hcw: convertWeightUnitTo(sale.Hcw, {unit: UnitTypes.MEDIUM})
        }))
    }

    getClientsOptions() {
        const {clients: {WData}, lang} = this.props;
        return WData[lang].map(item => {
            return {
                name: item.Value,
                value: item.ID
            }
        });
    }

    render() {
        const {t, handleSubmit, user: {UserType}} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Field
                    name="Price"
                    component={ReduxLabeledInput}
                    label={t("price")}
                    type="number"
                />
                {
                    (UserType === UserTypes.MANAGER || UserType === UserTypes.OWNER) &&
                    <Field
                        name="OperID"
                        component={OperatorChooser}
                        label={t("operator")}
                    />
                }
                <Field
                    name="Buyer"
                    component={ReduxLabeledSelect}
                    label={t("buyer")}
                    options={this.getClientsOptions()}
                />
                <Field
                    name="Hcw"
                    component={ReduxLabeledInput}
                    label={t("hotWeight")}
                    type="number"
                    unit={getUnit("weight", UnitTypes.MEDIUM)}
                />
                <ButtonGroup fixed renderInPortal={false}>
                    <Button buttonColor={"success"} buttonStyle={"round"} icon={<i className="fad fa-save"/>}/>
                </ButtonGroup>
            </form>
        )
    }
}

EditSale = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate
})(EditSale);

EditSale = connect(state => ({
    clients: state.dictionary.clients,
    lang: state.language.lang.lang,
    user: state.user.user,
    farm: state.location.farm
}))(EditSale);

export default withTranslation()(EditSale);