import React from "react";
import Header from "../../components/basics/header/Header";
import {connect} from "react-redux";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import Card from "../../components/basics/card/Card";
import {Link, Route, Switch} from "react-router-dom";
import EditSale from "./EditSale";
import SalesChooser from "./SalesChooser";
import {withTranslation} from "react-i18next";

export class SellView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            saleData: [],
            eventData: []
        }
    }

    onEditClick = (path, sale, events) => {
        const {history} = this.props;
        this.setState({
            saleData: sale,
            eventData: events,
        }, () => history.push(path));
    };

    onFinish = () => {
        const {farm, history} = this.props;
        this.setState({
            saleData: [],
            eventData: []
        }, () => history.push(`/${farm}/sell`));
    };

    getHeader = () => {
        const {t} = this.props;
        try {
            const {location: {pathname}} = this.props;
            let key = pathname.split("/")[3];
            if (key) return t("sale") + " - " + t("sellView.edition");
            return t("sale");
        } catch (e) {
            console.error(e);
            return t("sale");
        }
    };

    render() {
        const {saleData, eventData} = this.state;
        return (
            <div className="sell-view">
                <Header text={<>
                    {this.props.history.location.pathname !== `/${this.props.farm}/sell` &&
                    <Link to={`/${this.props.farm}/sell`}><i className={"fas fa-fw fa-arrow-left"}/></Link>}
                    {this.getHeader()}</>}/>
                <ViewContainer addPaddingForButtons>
                    <Card>
                        <Switch>
                            <Route path={`/${this.props.farm}/sell/edit/:id`}
                                   render={() => <EditSale sale={saleData} events={eventData}
                                                           onFinish={this.onFinish}/>}/>
                            <Route render={() => <SalesChooser onSelect={this.onEditClick}/>}/>
                        </Switch>
                    </Card>
                </ViewContainer>
            </div>
        );
    }
}

SellView = connect(state => ({
    farm: state.location.farm,
    events: state.events.events
}))(SellView);

SellView = withTranslation()(SellView);

export default SellView;