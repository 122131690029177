import React from "react";
import {connect} from "react-redux";
import eventsDB from "../../database/eventsDB";
import {EventTypes} from "../../constans/eventTypes";
import _ from "lodash";
import {sortAsString, sortDateStrings} from "../../utils/SortUtils";
import {getBuyerNameByID} from "../../utils/RaportsUtils";
import TableGrid from "../../components/basics/table-grid/TableGrid";
import moment from "moment";
import {convertWeightUnitTo} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";
import {withTranslation} from "react-i18next";

export class SalesChooser extends React.Component {
    constructor(props) {
        super(props);
        const {farm} = this.props;
        let events = eventsDB.getAllEventsForFarm(farm).filter(ev => ev.EvCode === EventTypes.SELL);
        this.state = {
            events: events,
            sales: this.prepareData(events),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(nextProps.events, this.state.events) && nextProps.events.length) {
            let sellEvents = nextProps.events.filter(ev => ev.EvCode === EventTypes.SELL);
            if (nextProps.events.length) {
                this.setState({
                    events: sellEvents,
                    sales: this.prepareData(sellEvents)
                });
            }
        }
    }

    changePath = (value) => {
        const {events} = this.state;
        this.props.onSelect(value ? `/${this.props.farm}/sell/edit/${value.SaleID}` : null, value, events);
    };

    prepareData(events) {
        let sales = [];
        let eventz = events.slice(0).filter(ev => ev.EvData.SaleID);
        let grouped = _.groupBy(eventz, ev => _.get(ev, "EvData.SaleID"));
        for (const value of Object.values(grouped)) {
            let sale = {
                AnmCnt: 0
            };
            sale.SaleID = value[0].EvData.SaleID;
            sale.OperID = value[0].EvData.OperID;
            sale.Price = value[0].EvData.Price;
            sale.Buyer = value[0].EvData.Buyer;
            sale.Date = value[0].EvTime;
            sale.Hcw = value[0].EvData.Hcw ? value[0].EvData.Hcw : 0;
            for (let ev of value) {
                sale.AnmCnt = sale.AnmCnt += ev.EvData.AnmCnt;
            }
            sales.push(sale);
        }
        return sales;
    }

    buyerValueFormatter = value => getBuyerNameByID(value);

    dateValueFormatter = (value) => {
        return value ? moment(value).format("DD.MM.YY") : "";
    };

    weightValueFormatter = (value) => {
        return convertWeightUnitTo(value, {unit: UnitTypes.MEDIUM, fixed: 1});
    };

    render() {
        const {sales} = this.state;
        const {t} = this.props;
        const headers = [
            {
                name: t("animalCount"),
                field: "AnmCnt"
            },
            {
                name: t("price"),
                field: "Price"
            },
            {
                name: t("hotWeight"),
                field: "Hcw",
                valueFormatter: this.weightValueFormatter
            },
            {
                name: t("buyer"),
                field: "Buyer",
                valueFormatter: this.buyerValueFormatter,
                customSort: sortAsString
            },
            {
                name: t("date"),
                field: "Date",
                valueFormatter: this.dateValueFormatter,
                customSort: sortDateStrings
            },
            {
                name: "",
                headerClassName: "index",
                itemClassName: "index",
                component: props => <i className="fad fa-edit" onClick={() => this.changePath(props.value)}/>
            }
        ];
        return (
            <div className="position-relative w-100">
                <TableGrid
                    data={sales}
                    headers={headers}
                    shouldIndex showPagination
                    paginationItems={20}
                />
            </div>
        )
    }
}

SalesChooser = connect(state => ({
    farm: state.location.farm,
    clients: state.dictionary.clients,
    events: state.events.events
}))(SalesChooser);

export default withTranslation()(SalesChooser);