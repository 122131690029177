import React from "react";
import Header from "../../components/basics/header/Header";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import LoadingComponent from "../../components/loading/LoadingComponent";
import ScrollAndTrigger from "../../components/scroll-and-trigger/ScrollAndTrigger";
import {change, Field, getFormSyncErrors, reduxForm, SubmissionError} from "redux-form";
import ReduxCheckbox from "../../components/basics/checkbox/ReduxCheckbox";
import NotFound from "../../components/NotFound";
import ReduxLabeledInput from "../../components/basics/input/labeled-input/ReduxLabeledInput";
import {isEqual,cloneDeep,get} from "lodash";
import {getLinkToDocument} from "../../utils/DocumentUtils";
import Card from "../../components/basics/card/Card";
import ButtonGroup from "../../components/basics/button/button-group/ButtonGroup";
import Button from "../../components/basics/button/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Auth from "@aws-amplify/auth";
import config from "../../conf/config";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import InfoBox from "../../components/basics/info-box/InfoBox";
import InputAlikeContainer from "../../components/input-alike-container/InputAlikeContainer";
import VersionFooter from "../../components/basics/version-footer/VersionFooter";
import ModalSignSuccessfull from "../../components/modals-new/sign-succesfull-modal/ModalSignSuccessfull";
import {redirect} from "../../actions/historyActions";
import {withTranslation} from "react-i18next";
import i18n from "i18next";

const initialValue = {
    read: undefined,
    loaded: false,
    fetched: false,
    time: 0,
    data: undefined
};

const FormName = "sign-up-view";

class SignUpView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            eula: initialValue,
            rodo: initialValue
        };
        this.props.initialize({
            phone: '+48'
        });
    }

    componentDidMount(){
        this.getDocuments();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const {lang,synchronousError,documents} = this.props;
        if (!isEqual(documents, prevProps.documents)) {
        this.getDocuments(this.props);
        }
        if (!isEqual(synchronousError, prevProps.synchronousError)||!isEqual(documents, prevProps.documents)) {
            let syncErrors ={};
            await i18n.changeLanguage(lang);
            Object.keys(synchronousError).map(e=>{syncErrors[e]=i18n.t(synchronousError[e])});
            if(syncErrors){
                this.props.dispatch({type: "@@redux-form/CHANGE",meta:{"form": "sign-up-view"},payload:{syncErrors}})}
        }
    }

    getDocuments = (props = this.props) => {
        const {documents} = props;
        let doc = cloneDeep(documents);
        doc.sort((o1, o2) => o2.DtaModTime - o1.DtaModTime);
        this.fetchDocument(getLinkToDocument(doc.filter(d => d.Type === "EULA")[0]), "eula");
        this.fetchDocument(getLinkToDocument(doc.filter(d => d.Type === "RODO")[0]), "rodo");
        this.props.dispatch(change(FormName, "eulaId", get(doc.filter(d => d.Type === "EULA")[0], "DocID")));
        this.props.dispatch(change(FormName, "rodoId", get(doc.filter(d => d.Type === "RODO")[0], "DocID")));
    };

    fetchDocument = (path, type = "") => {
        // console.log(path, type, "fetchDocumentfetchDocument");
        this.setState({
            [`${type}`]: initialValue
        }, () => {
            fetch(path).then(res => {
                if (!path || res.status !== 200) {
                    return Promise.reject(res);
                } else {
                    return res.text();
                }
            }).then(data => {
                this.setState({
                    [`${type}`]: {
                        ...initialValue,
                        fetched: true,
                        loaded: true,
                        data: data
                    }
                })
            }).catch(() => {
                this.setState({
                    [`${type}`]: {
                        ...initialValue,
                        fetched: true,
                        loaded: false
                    }
                })
            })
        })
    };

    render() {
        const {eula, rodo} = this.state;
        const {handleSubmit, t, submitting, submitSucceeded, error} = this.props;
        return (
            <div className={"pricing-view"}>
                <Header
                    text={<><Link to={"/"}><i className="fas fa-fw fa-arrow-left"/></Link>{t("signUpView.header")}</>}/>
                <ViewContainer>
                    <form onSubmit={handleSubmit}>
                        {
                            error &&
                            <InfoBox boxColor={"error"}>
                                {error}
                            </InfoBox>
                        }
                        <Card>
                            <Row>
                                <Col md={6}>
                                    <Field
                                        id={'login'}
                                        name={'login'}
                                        label={t("signUpView.login")}
                                        component={ReduxLabeledInput}
                                        required={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Field
                                        id={'mail'}
                                        name={'mail'}
                                        label={t("email2")}
                                        component={ReduxLabeledInput}
                                        required={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Field
                                        id={'name'}
                                        name={'name'}
                                        label={t("nameAndSurrname")}
                                        component={ReduxLabeledInput}
                                        required={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Field
                                        id={'address'}
                                        name={'address'}
                                        label={t("adres")}
                                        component={ReduxLabeledInput}
                                        required={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Field
                                        id={'pass'}
                                        name={'pass'}
                                        label={t("login.password")}
                                        type={"password"}
                                        component={ReduxLabeledInput}
                                        required={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Field
                                        id={'confirmPass'}
                                        name={'confirmPass'}
                                        label={t("signUpView.confirmPass")}
                                        type={"password"}
                                        component={ReduxLabeledInput}
                                        required={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Field
                                        id={'license'}
                                        name={'license'}
                                        label={t("signUpView.license")}
                                        component={ReduxLabeledInput}
                                        required={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Field
                                        id={'phone'}
                                        name={'phone'}
                                        label={t("signUpView.phone")}
                                        component={ReduxLabeledInput}
                                        required={true}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <div className={"license-box"} style={{minHeight: '10rem'}}>
                                        <label>{t("signUpView.eula")}</label>
                                        {
                                            <LoadingComponent isLoading={!eula.fetched}/>
                                        }
                                        {
                                            eula.fetched && eula.loaded &&
                                            <>
                                                <InputAlikeContainer>
                                                    <ScrollAndTrigger style={{maxHeight: '20rem'}}
                                                                      onTriggered={(time) => this.setState({
                                                                          eula: {...eula, time: time}
                                                                      })}>
                                                        <div dangerouslySetInnerHTML={{__html: eula.data}}/>
                                                    </ScrollAndTrigger>
                                                </InputAlikeContainer>
                                                <Field required={true} label={t("readAndAccept")} id="eula"
                                                       name="eula"
                                                       component={ReduxCheckbox}
                                                       disabled={!eula.time}/>
                                            </>
                                        }
                                        {
                                            eula.fetched && !eula.loaded &&
                                            <NotFound/>
                                        }
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className={"license-box"} style={{minHeight: '10rem'}}>
                                        <label>{t("signUpView.rodo")}</label>
                                        {
                                            <LoadingComponent isLoading={!rodo.fetched}/>
                                        }
                                        {
                                            rodo.fetched && rodo.loaded &&
                                            <>
                                                <InputAlikeContainer>
                                                    <ScrollAndTrigger style={{maxHeight: '20rem'}}
                                                                      onTriggered={(time) => this.setState({
                                                                          rodo: {...rodo, time: time}
                                                                      })}>

                                                        <div dangerouslySetInnerHTML={{__html: rodo.data}}/>
                                                    </ScrollAndTrigger>
                                                </InputAlikeContainer>
                                                <Field required={true} label={t("readAndAccept")} id="rodo"
                                                       name="rodo"
                                                       component={ReduxCheckbox}
                                                       disabled={!rodo.time}/>
                                            </>
                                        }
                                        {
                                            rodo.fetched && !rodo.loaded &&
                                            <NotFound/>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                        <ButtonGroup fixed={true} renderInPortal={false}>
                            <Button buttonStyle={"round"} buttonColor={"info"}
                                    isLoading={submitting}
                                    icon={<i className="fas fa-paper-plane"/>}/>
                        </ButtonGroup>
                    </form>
                </ViewContainer>
                <VersionFooter/>
                {submitSucceeded &&
                <ModalSignSuccessfull show={submitSucceeded} counter={5} action={() => {
                    this.props.dispatch(redirect("/login"))
                }}/>
                }
            </div>
        )
    }

}

async function submit(values, dispatch, props) {
    const {t} = props;
    const signUpParams = {
        password: values.pass,
        username: values.login,
        attributes: {
            phone_number: values.phone.replace(/\s/g, ""),
            email: values.mail,
            address: values.address,
            locale: props.locale || 'en',
            name: values.name,
            'custom:EULA': values.eulaId,
            'custom:RODO': values.rodoId,
            'custom:LicKey': values.license
        }
    };
    Auth.configure({
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    });
    return Auth.signUp(signUpParams).then(() => {
        dispatch({
            type: "USER_SET_ERROR",
            payload: 100
        })
    }).catch(err => {
        let errors = {};
        if (err.message.includes('AlreadyUsedLicense')) {
            errors.license = t("signUpView.licenseAlreadyUsed");
        } else if (err.message.includes('InvalidLicense')) {
            errors.license = t("signUpView.invalidLicense");
        } else if (err.name.includes("UsernameExists")) {
            errors.login = t("signUpView.userNameExist");
        } else {
            errors = {_error: t("signUpView.unexpectedError")};
        }

        throw new SubmissionError(errors)
    });
}

const validate = (values, props) => {
    let {t} = props;
    const errors = {};
    if (!(/^([a-zA-Z0-9]{8,})$/.test(values.login || ""))) {
        errors.login = t("signUpView.userNameShould");
    }
    if (!(/^(.{4,})$/.test(values.name || ""))) {
        errors.name = t("required");
    }
    if (!(/^(.{4,})$/.test(values.address || ""))) {
        errors.address = t("required");
    }
    if (!(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(values.pass || ""))) {
        errors.pass = t("signUpView.passwordShouldContain");
    }
    if (!(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(values.mail || ""))) {
        errors.mail = t("required")
    }
    if (!(/^([A-Z0-9]{4}-){8}[A-Z0-9]{4}$/.test(values.license || ""))) {
        errors.license = t("signUpView.licenseInput");
    }
    if (!values.confirmPass || values.confirmPass !== values.pass) {
        errors.confirmPass = t("required");
    }
    let phoneNumber = parsePhoneNumberFromString(values.phone || "");
    if (values.phone && values.phone.charAt(0) !== "+") {
        errors.phone = t("errors.phoneNumberError");
    } else {
        if (!phoneNumber || !phoneNumber.isValid()) {
            errors.phone = t("errors.phoneError");
        }
        if (!values.phone) {
            errors.phone = t("required");
        }
    }

    if (!values.rodo) {
        errors.rodo = t("required");
    }
    if (!values.eula) {
        errors.eula = t("required");
    }
    return errors;
};
SignUpView = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(SignUpView);
SignUpView = withTranslation()(SignUpView);
SignUpView = connect(state => ({
    locale: state.language.locale,
    documents: state.documents.documents,
    lang: state.language.lang.lang,
    synchronousError: getFormSyncErrors(FormName)(state)
}))(SignUpView);
export default SignUpView;



