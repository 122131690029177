import _ from "lodash";
import {updateEvent} from "../../actions/eventsActions";
import {convertWeightToBaseUnit} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";
import {EventTypes} from "../../constans/eventTypes";

export function submit(values, dispatch, props) {
    const {SaleID, Price, OperID, Buyer, Hcw} = values;
    const {events, user} = props;
    let eventsToUpdate = [];
    let _events = _.cloneDeep(events).filter(ev => ev.EvCode === EventTypes.SELL && ev.EvData.SaleID === SaleID);
    for (let event of _events) {
        let before = _.cloneDeep(event);
        event.EvData.Price = +Price;
        event.EvData.OperID = OperID;
        event.EvData.Buyer = Buyer;
        event.EvData.Hcw = convertWeightToBaseUnit(+Hcw, {fromUnit: UnitTypes.MEDIUM});
        if (!_.isEqual(before, event)) {
            event.DtaModTime = +new Date();
            delete event.$loki;
            eventsToUpdate.push(event);
        }
    }

    if (eventsToUpdate.length) dispatch(updateEvent(eventsToUpdate, user.ClientID, user.LocalUserID));
    props.onFinish();
}

export function validate(values, props) {
    const errors = {};
    if (!values.Price) {
        errors.Price = props.t("required");
    }
    if (!values.OperID) {
        errors.OperID = props.t("required");
    }
    if (!values.Buyer) {
        errors.Buyer = props.t("required");
    }
    if (!values.Hcw) {
        errors.Hcw = props.t("required");
    }
    return errors;
}